import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, combineLatest } from 'rxjs';
import * as cloneDeep from 'lodash/fp/cloneDeep';

import {
  SearchStateFilter,
  SearchStateInterface,
  SearchStateParams,
  SearchStep,
  SortOptions,
  TruckCardTypes,
  UpdateStepOptions
} from '~/core/store/search/search.model';
import { listEnterLeave, listEnterLeaveItem, listItem, toggleHeight } from '~/animations';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { SearchService } from '~/core/store/search/search.service';
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';
import { Truck } from '~/core/store/trucks/trucks.model';
import { HtmlRoutePages } from '~/app-routing.model';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DealershipPopupComponent } from '~/popups/dealership-popup/dealership-popup.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BreadCrumbUrlService } from '../../core/services/breadcrumb/breadcrumbUrl.service';
import { Store } from '@ngxs/store';
import { LocalesStateInterface, LocalesStateLang } from '~/core/store/locales/locales.model';
import { AppState } from '~/core/store';
import { NotificationPopupComponent } from '../../popups/notification-popup/notification-popup.component';
import { BreadcrumbService } from 'angular-crumbs-2';
import { filter } from 'rxjs/operators';
import { SoldPopupComponent } from '~/popups/sold-popup/sold-popup.component';
import { ToggleFeatureService } from '~/core/services/togglefeature/togglefeature.service';
import { DeviceDetectorService } from 'ngx-device-detector';

import { TruckComponent } from '../../components/truck/truck.component';
import { TrucksService } from 'src/app/core/store/trucks/trucks.service';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { LocalesService } from '~/core/store/locales/locales.service';
import { regionEnum } from '~/core/declaration/declaration';
import { AssetService } from '~/core/services/assets/asset.service';
const { v4: uuidv4 } = require('uuid');

@AutoUnsubscribe()
@Component({
  selector: 'volvo-stock-page',
  templateUrl: './stock-page.component.html',
  styleUrls: ['./stock-page.component.scss'],
  animations: [toggleHeight, listItem, listEnterLeave, listEnterLeaveItem]
})
export class StockPageComponent implements OnInit, OnDestroy, AfterViewInit {
  trucks: Truck[];
  otherTrucks: Truck[];
  search: SearchStateFilter;
  selectedFiltersCount: number;
  searchQuery: SearchStateParams = undefined;
  cardsType = TruckCardTypes.list;
  TruckCardTypes = TruckCardTypes;
  isLoaded = false;
  isNotFound = false;
  TruckLocations: string = '';
  dealershipLocationCount: number = 0;
  private s1: Subscription;
  private s2: Subscription;
  private s3: Subscription;
  private s4: Subscription;
  otherTrucksLength: number = 0;
  trucksLength: number = 0;
  selectedPageNo: number = 1;
  showNoStock: boolean;
  noLoadCountry: any;
  country: string;
  searchVal: any;
  emailFlag: boolean = false;
  notificationRef: any;
  notSeenNotificationHighlight: boolean = false;
  highlightFeature: boolean = false;
  notificationFeature: boolean = false;
  soldDialogRef: MatDialogRef<SoldPopupComponent, any>;
  isAnySelected: boolean = false;
  isSelectedSite: boolean = false;
  //UK,NS,TX,Starts here
  categoryStep: SearchStep = {
    id: uuidv4(),
    isSelected: false,
    isVisible: false,
    isExpanded: false,
    title: 'Offer',
    inputPlaceholder: '',
    value: 'All',
    initValue: 'All',
    list: [
      {
        name: 'Volvo Selected',
        id: 'VolvoSelected'
      },
      {
        name: 'Volvo Approved',
        id: 'VolvoApproved'
      },
      {
        name: 'Volvo Economy',
        id: 'VolvoEconomy'
      },
    ],
  };

  @Input() title: string;
  currentCategory: string;
  pageTitle = '';
  regionalSettings: RegionSettings;
  regionalSettingsSnap: RegionSettings;
  setCategory(category: string) {
    if (!category || category === "Landing") {
      this.categoryStep.value = "All";
      this.searchService.updateStep({
        key: "advertisementCategory", value: this.categoryStep, expandingFilter: "advertisementCategory",reloadFlag: false
      });
    } else {
      this.categoryStep.value = category;
      this.searchService.updateStep({
        key: "advertisementCategory", value: this.categoryStep, expandingFilter: "advertisementCategory",reloadFlag: false
      });
    }

    switch (category) {
      case "Selected;Approved": {
        this.showCategoryButtons = false;
        this.showFilter = true;
        this.showChips = true;
        this.showStockOptions = true;
        this.pageTitle = this.translateService.instant('uk/stock/selectedapproved/metatitle'.toLowerCase());
        break;
      }
      case "Economy": {
        this.showCategoryButtons = false;
        this.showFilter = true;
        this.showChips = true;
        this.showStockOptions = true;
        this.pageTitle = this.translateService.instant('uk/stock/economy/metatitle'.toLowerCase());
        break;
      }
      case "Remaining Warranty": {
        this.showCategoryButtons = false;
        this.showFilter = true;
        this.showChips = true;
        this.showStockOptions = true;
        this.pageTitle = this.translateService.instant('uk/stock/warranty/metatitle'.toLowerCase());
        break;
      }
      default: {
        this.showCategoryButtons = false;
        this.showFilter = true;
        this.showChips = true;
        this.showStockOptions = true;
        this.pageTitle = this.translateService.instant('uk/stock/metatitle'.toLowerCase());
      }
    }

    this.currentCategory = category;
  };

  resetAllFiltersUK(): void {
    if(this.regionalSettings.site === 'volvoselected'){
      //TODO CLEAR LOCAL STORAGE
      localStorage.setItem('searchParam', '');
      sessionStorage.setItem('resetCountry', 'true');

      this.searchService.resetSearch();
      // TODO: improve this
      this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)], {
        relativeTo: this.activatedRoute
      });
    }else{ 
      const oldValue = this.store.selectSnapshot<SearchStateInterface>((state: AppState) => state.search).filter.steps.advertisementCategory;
      localStorage.setItem('searchParam', '');
      this.searchService.resetSearch();

      this.searchService.updateStep({
        expandingFilter: "",
        key: "advertisementCategory",
        value: oldValue,
        reloadFlag: false
      });
    }
  }

  newTrucks: Truck[];
  showWarrantyText: boolean = false;
  showSelectedText: boolean = false;
  showEconomyText: boolean = false;
  showText: boolean = false;

  showCategoryButtons: boolean = true;
  showFilter: boolean = false;
  showChips: boolean = true;
  showStockOptions: boolean = true;
  hasQueryParam:boolean = false;

  navigationTrigger: string;

  private region: string;
  private site: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public searchService: SearchService,
    public trucksService: TrucksService,
    private globalUtils: GlobalUtils,
    private localizeRouterService: LocalizeRouterService,
    private breadCrumbUrlService: BreadCrumbUrlService,
    private titleService: Title,
    private metaTagService: Meta,
    private translateService: TranslateService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    public store: Store,
    private breadcrumbService: BreadcrumbService,
    public togglefeature: ToggleFeatureService,
    public regionService: RegionalSettingsService,
    public localesService: LocalesService,
    public assetService: AssetService
  ) {
    if (this.globalUtils.isBrowser() && ScreenUtils.isPhone()) {
      this.cardsType = TruckCardTypes.card;
    }
      this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
      this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.region)[0]);
      this.site = this.regionalSettings.site;
      if(this.site === "volvoselected") this.isSelectedSite = true;
      if(this.region === regionEnum.UK){
        this.setCategory('Selected;Approved;Economy;Remaining Warranty'); //These sites stock has more priority on offertypes then country
        if(localStorage.getItem('offerType')){
          this.categoryStep.value = localStorage.getItem('offerType');
          
          this.s1 = this.searchService.filter$
          .subscribe((searchOptions: SearchStateFilter) => {
            this.search = cloneDeep(searchOptions);
          });

          this.search.steps.offerAndWarranty.isSelected = true;
          this.search.steps.offerAndWarranty.value = this.categoryStep.value;
          this.search.steps.offerAndWarranty.isExpanded = true;
          this.search.requestingFilter = "offerAndWarranty";
          this.search.steps.offerAndWarranty.initValue = "all";

          const updateOptions: UpdateStepOptions = {
            key: "offerAndWarranty",
            value: this.search.steps.offerAndWarranty,
            expandingFilter: "",
            reloadFlag: false
          };
          
          this.searchService.updateStep(updateOptions);
        }
      }

    this.localesService.country$.subscribe((lang) => {
      if (lang) {
        this.country = lang.curCountry.label;
      }
    });

    this.checkUrlParams();
    const step = this.store.selectSnapshot<SearchStateInterface>((state: AppState) => state.search).filter.steps[
      'stockLocationCountry'
    ];

    //The below condition is checked to pass country as empty when reset filter is clicked and the page is navigated back to ourstock from truck page.
    this.searchVal = localStorage.getItem('searchParam');
    let stepValue: string = step.value as string;

    if (!this.emailFlag) {
      if (this.searchVal) {
        let searchParamVal = JSON.parse(this.searchVal);
        if (!searchParamVal.qf) { //when reset filter is clicked
          if (sessionStorage.getItem("resetCountry"))
            this.country = '';
          else
            step.value = '';
        }
        else {
          if (stepValue) {
            if (this.country) {
              if (!sessionStorage.getItem("resetCountry") && !stepValue.includes(this.country))
                step.value = '';
            }
            else
              this.country = ''; //Europe (no country)
          }
          else {
            if (!sessionStorage.getItem("resetCountry")) step.value = '';
            else this.country = '';
          }
        }
      }
      else {
        step.value = '';
      }
    }
    else
      this.country = '';
    if(this.isSelectedSite || this.site === 'TX'){ //These sites stock has more priority on country then ofertypes unlike UK/NS
        this.searchService.updateStep({
          expandingFilter: '',
          key: 'stockLocationCountry',
          value: {
            ...step,
            isSelected: true,
            value: step.value === '' ? this.country : step.value
          },
          reloadFlag: true
        });
      }
    this.initSubscriptions();
  }

  links = [
    { label: 'Remaining  Warranty', path: HtmlRoutePages.warranty },
    { label: 'Volvo Selected & Approved', path: HtmlRoutePages.selected },
    { label: 'Volvo Economy', path: HtmlRoutePages.economy }
  ];

  async ngOnInit() {
    this.highlightFeature = await this.togglefeature.getFeatureToggle('Highlights');

    this.notificationFeature = await this.togglefeature.getFeatureToggle('Notifications');
    //this.notificationFeature = true;

    this.notSeenNotificationHighlight = JSON.parse(localStorage.getItem("notSeenNotificationHighlight"));
    if (this.globalUtils.isBrowser() && this.deviceService.isMobile()) {
      this.cardsType = TruckCardTypes.card;
    }
  }

    ngAfterViewInit() {
        {
        var key = 'stock/metaTitle'.toLowerCase();
        var title = this.translateService.instant(key);
        this.router.events.subscribe(() => {        
        if (this.pageTitle == '') {
            this.titleService.setTitle(title)
        }            
        else this.titleService.setTitle(title);
        this.metaTagService.updateTag({
          name: 'og:title',
          content: this.translateService.instant('stock/metatitle'.toLowerCase())
        });
        this.metaTagService.updateTag({
          name: 'og:description',
            content: this.translateService.instant('stock/metadescription'.toLowerCase()),
        });
        this.metaTagService.updateTag({
          name: 'og:image',
          alt: 'alt',
            content: this.translateService.instant('stock/altimage'.toLowerCase()),
        });
            // Additional traditional meta tags for search engines
            this.metaTagService.updateTag({ name: 'keywords', content: this.translateService.instant('stock/metakeywords'.toLowerCase()) });
      });
        if (this.pageTitle == '') {
            this.titleService.setTitle(title)
        }
        else this.titleService.setTitle(title);
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('stock/metatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
          content: this.translateService.instant('stock/metadescription'.toLowerCase()),
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
          content: this.translateService.instant('stock/altimage'.toLowerCase()),
      });
        // Additional traditional meta tags for search engines
        this.metaTagService.updateTag({ name: 'keywords', content: this.translateService.instant('stock/metakeywords'.toLowerCase()) });
      //this.checkUrlParams();
      if(!this.hasQueryParam)
      {
        this.initSubscriptions();
      }
      this.notSeenNotificationHighlight = JSON.parse(localStorage.getItem("notSeenNotificationHighlight"));
      }
    
  }

  ngOnDestroy(): void {
    this.s1.unsubscribe();
    this.s2.unsubscribe();
    this.s3.unsubscribe();
    this.s4.unsubscribe();
    this.searchService.resetSearch();
  }

  resetAllFilters(): void {
    //TODO CLEAR LOCAL STORAGE
    localStorage.setItem('searchParam', '');
    sessionStorage.setItem('resetCountry', 'true');

    this.searchService.resetSearch();
    // TODO: improve this
    this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)], {
      relativeTo: this.activatedRoute
    });
  }

  routePage(link: any): void {
    this.router.navigate([this.localizeRouterService.translateRoute(link.path)], { relativeTo: this.activatedRoute });
  }

  showHideText(label: string) {
    switch (label) {
      case 'Remaining  Warranty':
        this.showWarrantyText = true;
        this.showSelectedText = false;
        this.showEconomyText = false;
        break;
      case 'Volvo Selected & Approved':
        this.showWarrantyText = false;
        this.showSelectedText = true;
        this.showEconomyText = false;
        break;
      case 'Volvo Economy':
        this.showWarrantyText = false;
        this.showSelectedText = false;
        this.showEconomyText = true;
        break;
    }
  }

  onCardsType(cardsType: TruckCardTypes): void {
    this.cardsType = cardsType;
  }

  trackByFn(index: number, item: Truck) {
    return item.stockNumber;
  }

  private async loadTrucksOnFilterChange(searchQuery: SearchStateParams): Promise<void> {
    this.isLoaded = false;
    this.searchService.getSearch(searchQuery);
    //this.searchService.getAggregations(searchQuery);
  }

  private initSubscriptions(): void {
    if(this.regionalSettings.site === 'volvoselected' || this.regionalSettings.site === 'TX'){
      this.s1 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
        this.selectedFiltersCount = 0;
        this.selectedPageNo = searchOptions.page;
        if(!this.selectedPageNo)
          this.selectedPageNo = 1;
        if (SortOptions.oldestAge !== searchOptions.sort) {
          this.selectedFiltersCount++;
        }
  
        Object.values(searchOptions.steps).forEach(item => {
          if (item.isSelected && item.isVisible) {
            this.selectedFiltersCount++;
            switch (item.title) {
              case 'Dealership Location':
                this.dealershipLocationCount = item.value.split(';').length;
                break;
              case 'Truck Location':
                this.TruckLocations = item.value;
                break;
            }
          } else {
            switch (item.title) {
              case 'Dealership Location':
                if (item.isSelected)
                  this.dealershipLocationCount = item.value.split(';').length;
                else
                  this.dealershipLocationCount = 0;
                break;
              case 'Truck Location':
                this.TruckLocations = '';
                break;
            }
          }
        });
  
        this.search = searchOptions;
        if ((this.selectedFiltersCount||this.dealershipLocationCount) === 0) {
          if (this.notificationRef) {
            this.notificationRef.close();
          }
        }
      });
  
      this.s2 = this.searchService.params$.subscribe((searchQuery: SearchStateParams) => {
        this.searchQuery = searchQuery;
        let searchParam = localStorage.getItem('searchParam');
        if (searchQuery.qf || !searchParam)
          this.loadTrucksOnFilterChange(searchQuery);
      });
  
      this.s3 = this.searchService.trucksList$.subscribe((trucks: Truck[]) => {
        if (trucks) {
          
          this.trucks = trucks.map(t => (t = new Truck(t)));
          this.trucksLength = this.trucks.length;
  
          //Show No stock only on first time/page and not on click of next page.
          if (this.trucksLength > 0) this.showNoStock = false;
          else if (this.trucksLength === 0 && this.selectedPageNo > 1) this.showNoStock = false;
          else this.showNoStock = true;
  
          this.isLoaded = true;
          this.breadCrumbUrlService.setUrl(this.router.url);
        }
      });
  
      this.s4 = this.searchService.trucksListOthers$.subscribe((otherTrucks: Truck[]) => {
        if (otherTrucks) {
          
          this.otherTrucks = otherTrucks.map(t => (t = new Truck(t)));
          this.otherTrucksLength = this.otherTrucks.length;
          this.isLoaded = true;
          if (this.trucks.length === 0 && this.otherTrucks.length === 0) this.showNoStock = false;
          this.breadCrumbUrlService.setUrl(this.router.url);
        }
      });
    }else{
      this.s1 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {

        this.isAnySelected = Object.values(searchOptions.steps).map(m =>  m.title !== "Offer" && m.value !== "advertisementCategory" && m.isSelected === true && m.isVisible === true).some(m => m === true);
        this.search = searchOptions;
        if (!this.isAnySelected) {
          if (this.notificationRef) {
            this.notificationRef.close();
          }
        }
      });
  
      this.s2 = this.searchService.params$.subscribe((searchQuery: SearchStateParams) => {
        this.searchQuery = searchQuery;
        let searchParam = localStorage.getItem('searchParam');
        if (searchQuery.qf || !searchParam)
          this.loadTrucksOnFilterChange(searchQuery);
      });
  
      this.s3 = combineLatest([this.searchService.trucksList$, this.searchService.totalTrucks$]).subscribe(([trucks, totalTrucks]) => {
        if (trucks) {
          this.trucks = trucks.map(t => t = new Truck(t));
          this.isLoaded = true;
          this.breadCrumbUrlService.setUrl(this.router.url);
          this.setPosition();
        }
      });
  
      this.s4 = this.trucksService.newestTrucks$.subscribe((trucks: Truck[]) => {
        if (trucks) {
          this.newTrucks = trucks.map(t => t = new Truck(t));
          this.isLoaded = true;
         
        }
      });
    }
    
    this.activatedRoute.data
      .pipe(filter((p: any) => p.isNotFound))
      .subscribe(data => {
        this.isNotFound = data.isNotFound;
        if (this.isNotFound) {
          this.openSoldPopup();
        }
        
      });
  }

  onDealershipClick(): void {
    this.dialog.open(DealershipPopupComponent, {
      panelClass: 'DealershipPopup'
    });
  }

  private checkUrlParams(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams as SearchStateParams;
    const emailQueryParams = this.activatedRoute.snapshot.queryParams;
    if (emailQueryParams && emailQueryParams.email)
      this.emailFlag = emailQueryParams.email;

    if (queryParams && queryParams.qf) {
      this.searchService.setSearchFromUrl(queryParams);
    }
  }

  setPosition() {
    let postions = this.breadCrumbUrlService.getPageposition();
    window.scrollTo(postions.screenLeft, postions.screenTop);
    this.breadCrumbUrlService.setPageposition(0, 0);
  }

  onSetNotificationClick() {
    if(this.isSelectedSite || this.region === regionEnum.TX){
      if(this.selectedFiltersCount > 0 || this.dealershipLocationCount > 0) this.isAnySelected = true;
      else this.isAnySelected = false;
    }
    var position = "", cssClass = "volvo-notification-popup";
    if(!this.isAnySelected){
      cssClass = "volvo-no-filter-popup";
    }
    if (ScreenUtils.isTablet()) {
      position = "top";
    }
    this.notificationRef = this.dialog.open(NotificationPopupComponent, {
      panelClass: cssClass,
      position: { top: position },
      disableClose: true,
      data: { isFilterSelected : this.isAnySelected }
    });
  }

  onPageLoading($event) {
    this.isLoaded = $event.value;
  }

  onHighlightNotificatinClose() {
    localStorage.setItem("notSeenNotificationHighlight", "false");
    this.notSeenNotificationHighlight = false;
  }

  private openSoldPopup(): void {
    if (this.isNotFound) {
      this.soldDialogRef = this.dialog.open(SoldPopupComponent, {
        panelClass: 'volvo-popup-sold',
        closeOnNavigation: true,
        disableClose: true,
        data: { truckRemoved: true }
      });
    }
  }

  private loadNewstTrucks(): void {
    this.trucksService.getNewestTrucks();
  }

}

