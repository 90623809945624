<section *ngIf="globalUtils.isBrowser()"  class="BestTruck" [class.isAside]="isTruckPage">
  <h2 class="BestTruck-title" data-cy="hightlited-vehicle">{{ title}}</h2>
  <div class="BestTruck-wrap" *ngIf="isLoaded; else loading">
    <div class="BestTruck-items" data-cy="highlighted-items">
      <drag-scroll #slider class="drag-scroll-style"
      (reachesLeftBound)="leftBoundStat($event)"
      (reachesRightBound)="rightBoundStat($event)"
      >
        <div drag-scroll-item class="dragScroll" *ngFor="let truck of bestTrucks">
          <volvo-truck [truck]="truck" [truckdata]="truck" [openTruckAsPage]="true" [homePageCard]="true"></volvo-truck>
        </div>
      </drag-scroll>
      <button (click)="slider.moveLeft()" [disabled]="leftNavDisabled" class="BestTruck-moveLeft sliderButton" data-cy="besttruck-moveleft" aria-label="Move left">
        <i class="fal fa-chevron-left"></i>
        <span class="sr-only">Move left</span>
      </button>
      <button (click)="slider.moveRight()" [disabled]="rightNavDisabled" class="BestTruck-moveRight sliderButton" data-cy="besttruck-moveright" aria-label="Move right">
        <i class="fal fa-chevron-right"></i>
        <span class="sr-only">Move right</span>
      </button>
    </div>
  </div>
  <ng-template #loading>
    <div class="BestTruck-wrap">
      <div class="BestTruck-items">
        <drag-scroll #slider class="drag-scroll-style">
          <div drag-scroll-item class="dragScroll" *ngFor="let product of [0, 1, 2, 3, 4, 5]" >
            <volvo-truck [openTruckAsPage]="true" [homePageCard]="true" [isSkeleton]="true"></volvo-truck>
          </div>
        </drag-scroll>
      </div>
    </div>
  </ng-template>
</section>