import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs/';
import { combineLatest } from 'rxjs';

import { SearchStateFilter, SearchStep, SearchStepKeys } from '~/core/store/search/search.model';
import { Bucket, BucketAggregations } from '~/core/store/buckets/buckets.model';
import { BucketsService } from '~/core/store/buckets/buckets.service';
import { SearchService } from '~/core/store/search/search.service';
import { toggleHeight } from '~/animations';
import { TranslationService } from '~/core/services/translations/translation-service';
import { RegionalSettingsStateInterface, RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { LocalesStateRegion } from '~/core/store/locales/locales.model';
import { SearchState } from 'src/app/core/store/search/search.state';
import { Options } from '@angular-slider/ngx-slider';
import { FilterService } from './filter.service';
import { regionEnum } from '../../core/declaration/declaration';
import { RegionalSettingsService } from '../../core/store/regionalSettings/regionalSettings.service';

@AutoUnsubscribe()
@Component({
  selector: 'volvo-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [toggleHeight],
})
export class FilterComponent implements OnInit, OnDestroy, AfterViewInit {
  SearchStepKeys = SearchStepKeys;

  aggregations: BucketAggregations = undefined;
  search: SearchStateFilter;
  regionalSettings: RegionSettings;
  private s0: Subscription;
  private s1: Subscription;
  region: string;
  regionEnum=regionEnum;

  constructor(private searchService: SearchService, private translationService: TranslationService, store: Store, private searchState: SearchState,
    private regionalSettingsService: RegionalSettingsService, private filterService: FilterService) {
    this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.region)[0]);
    this.initSubscriptions();
    this.resetScroll();
    this.translationService.loadTrans(this.translationService.getCurrentLanguage()).then();

  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {
   if(this.regionalSettings.site === "volvoselected")
   {
      this.search.steps.offerAndWarranty.isVisible=false;
      this.search.steps.stockLocationCity.isVisible=false;
      this.search.steps.cabType.isVisible=false;
      this.search.steps.advertisementCategory.isVisible=false;
   }
   if(this.region===this.regionEnum.TX)
   {
      this.search.steps.offerAndWarranty.isVisible=false;
      this.search.steps.stockLocationCity.isVisible=false;
      this.search.steps.advertisementCategory.isVisible=false;
      this.search.steps.emission.isVisible=false;
      this.search.steps.make.isVisible=false;
      this.search.steps.fuelType.isVisible=false;
      this.search.steps.stockLocationCountry.isVisible=false;
   }
  }

  ngOnDestroy(): void { }

  onPanelOpened(value: SearchStep, key: string) {
    

  }

  onFilterChange(value: SearchStep, key: string): void {
    
    this.setScrollPosition(value.title);
    this.searchService.updateStep({ key, value, reloadFlag: false, expandingFilter: '' });
    if(this.regionalSettings.site === "volvoselected")
   {
      this.search.steps.offerAndWarranty.isVisible=false;
      this.search.steps.stockLocationCity.isVisible=false;
      this.search.steps.cabType.isVisible=false;
      this.search.steps.advertisementCategory.isVisible=false;
    }
    if(this.region===this.regionEnum.TX)
    {
      this.search.steps.offerAndWarranty.isVisible=false;
      this.search.steps.stockLocationCity.isVisible=false;
      this.search.steps.advertisementCategory.isVisible=false;
      this.search.steps.emission.isVisible=false;
      this.search.steps.make.isVisible=false;
      this.search.steps.fuelType.isVisible=false;
      this.search.steps.stockLocationCountry.isVisible=false;
    }
    if(this.region===this.regionEnum.UK)
      localStorage.setItem('offerType', '');
  }

  onFilterReset(value: SearchStep, key: string): void {
    
    this.setScrollPosition(value.title);
    this.searchService.resetStep(key,'');
  }

  trackByFn(index: number, item: SearchStep): string {
    return item.id;
  }

  originalOrder(): number {
    return 0;
  }

  private initSubscriptions(): void {
    this.s0 = this.searchService.aggregations$.subscribe((aggregations) => {
      if (aggregations) {
        this.aggregations = Object.keys(aggregations).length === 0 ? undefined : aggregations;
        this.checkSuperstructureHasFilters()
      }
    }
    );

    this.s1 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
      this.search = searchOptions;
      if (this.regionalSettings?.singleCountryRegion) {
        this.search.steps.stockLocationCountry.isVisible = false;
        this.search.steps.stockLocationCountry.isExpanded = false;
      }
      if(this.region===this.regionEnum.UK){
        this.search.steps.emission.isVisible=false;
        this.search.steps.make.isVisible=false;
        this.search.steps.fuelType.isVisible=false;
        this.search.steps.stockLocationCountry.isVisible=false;
       }
       if(this.regionalSettings.site === "volvoselected"){
        {
          this.search.steps.offerAndWarranty.isVisible=false;
          this.search.steps.stockLocationCity.isVisible=false;
          this.search.steps.cabType.isVisible=false;
          this.search.steps.advertisementCategory.isVisible=false;
        }
       }
       if(this.region===this.regionEnum.TX){
          this.search.steps.offerAndWarranty.isVisible=false;
          this.search.steps.stockLocationCity.isVisible=false;
          this.search.steps.advertisementCategory.isVisible=false;
          this.search.steps.emission.isVisible=false;
          this.search.steps.make.isVisible=false;
          this.search.steps.fuelType.isVisible=false;
          this.search.steps.stockLocationCountry.isVisible=false;
       }
      this.search = this.searchState.checkAndSetZarPrice(this.search);
    });
  }
    checkSuperstructureHasFilters() {
        //Need of hiding superstructure when there is no filter/element in it
        if (this.aggregations && this.aggregations.superstructureType.totals.length < 1)
            this.search.steps.superstructureType.isVisible = false;
    }
  setScrollPosition(filterType: string) {
    // Get the current page scroll position
    let clicked = true;
    let scroll = true;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    if (filterType === 'Price' || filterType === 'Mileage' || filterType === 'Engine HP' || filterType === 'First Registration') {
      window.onscroll = function () {
        if (!scroll) {
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        }
        window.location.pathname.endsWith('/search')
          ? window.scrollTo(scrollLeft, scrollTop)
          : (window.onclick = function () { });
        scroll = false;
      };
    } else {
      window.onscroll = function () { };
      window.onclick = function () {
        if (!clicked) {
          scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        }
        window.location.pathname.endsWith('/search')
          ? window.scrollTo(scrollLeft, scrollTop)
          : (window.onclick = function () { });
        clicked = false;
      };
    }
  }
  resetScroll() {
    window.onclick = function () { };
    window.onscroll = function () { };
  }
}
