import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

import { CurrencyCode, Truck } from '~/core/store/trucks/trucks.model';
import { LocalesStateLang, LocalesStateLangList } from '~/core/store/locales/locales.model';
import { SearchStateFilter, TruckCardTypes } from '~/core/store/search/search.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { SearchService } from '~/core/store/search/search.service';
import { HtmlRoutePages } from '~/app-routing.model';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbUrlService } from '../../core/services/breadcrumb/breadcrumbUrl.service';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { regionEnum } from '../../core/declaration/declaration';
import { RegionalSettingsService } from '../../core/store/regionalSettings/regionalSettings.service';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';

export enum AnimationState {
  IN = 'in',
  OUT = 'out'
}

@AutoUnsubscribe()
@Component({
  selector: 'volvo-truck',
  templateUrl: './truck.component.html',
  styleUrls: ['./truck.component.scss'],
})
export class TruckComponent implements OnInit, OnDestroy {
  @Input() truck: Truck;
  @Input() truckdata: Truck;
  @Input() openTruckAsPage = false;
  @Input() isSkeleton = false;

  @Input() set cardSmall(bool: boolean) {
    this.cardSmallValue = bool;
  }

  @Input() set homePageCard(bool: boolean) {
    this.homePageCardValue = bool;
  }

  @Input() set cardType(truckCardType: TruckCardTypes) {
    this.cardTypeValue = truckCardType;
  }

  CurrencyCode = CurrencyCode;
  HtmlRoutePages = HtmlRoutePages;
  TruckCardTypes = TruckCardTypes;
  isTrailer = true;

  truckPicture: string[] = [];
  truckId: string;
  truckUrl: string;
  curLang: LocalesStateLangList;
  truckMakeModel: string = '';
  locationImage:string='';
  region:string;
  regionEnum=regionEnum;
  //truckMakeModel: string = '';

  private s0: Subscription;
  private s1: Subscription;
  advertisementTitle: string = '';
  @HostBinding('class.isList') cardTypeValue = TruckCardTypes.card;
  @HostBinding('class.isSmall') cardSmallValue = false;
  @HostBinding('class.isSmall') homePageCardValue = false;
  regionalSettings: RegionSettings;
  imageLoaded:boolean=true;
  site: string;
  isSelectedSite: boolean = false;
  showLocalCurrency: boolean = false;
  isOtherCategory: boolean = false;
  constructor(
    private router: Router,
    private localesService: LocalesService,
    private globalUtils: GlobalUtils,
    private searchService: SearchService,
    private localizeRouterService: LocalizeRouterService,
    private metaTagService: Meta,
    private meta: Meta,
    private translateService: TranslateService,
    private breadCrumbUrlService: BreadCrumbUrlService,
    private regionalSettingsService:RegionalSettingsService,
    store: Store
  ) {
    this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.region)[0]);
    if(this.regionalSettings && this.regionalSettings.site === "volvoselected") this.isSelectedSite = true;
    this.initSubscriptions();
  }

  ngOnInit(): void {
      if (this.truck) {
      this.initTruck();
      
      if (this.truck && this.truck.pictures && this.truck.pictures[1] && this.truck.pictures[1].filenameSmall) {
        this.truckPicture[1] = this.truck.pictures[1].filenameSmall;
      }
      if (this.truck && this.truck.pictures && this.truck.pictures[0] && this.truck.pictures[0].filenameSmall) {
        this.truckPicture[0] = this.truck.pictures[0].filenameSmall;
      }
      if (this.truck.specification.advertisementTitle) {
        this.advertisementTitle = this.truck.specification.advertisementTitle;
      }

      if(this.truck?.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && this.truck?.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR && this.truck?.ShowPrice){
        this.showLocalCurrency = true;
      }
    }
    // this.meta.addTags([
    //   {
    //     name: 'keywords',
    //     content: 'Angular SEO Integration, Music CRUD, Angular Universal'
    //   },
    //   { name: 'robots', content: 'index, follow' },
    //   { name: 'author', content: 'Digamber Singh' },
    //   {
    //     name: 'viewport',
    //     content: 'width=device-width, initial-scale=1'
    //   },
    //   { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
    //   { charset: 'UTF-8' }
    // ]);
    this.locationImage='/assets/icons/Geopin.jpg'
  }

  ngOnDestroy(): void { }

  async openTruck(event: MouseEvent): Promise<void> {
    this.router.events.subscribe(() => {
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.truck.MetaTitle + this.translateService.instant('truckpage/metaTitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.truck.MetaDescription + this.translateService.instant('truckpage/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.truck.MetaAltImage + this.translateService.instant('truckpage/altImage'.toLowerCase())
      });
    });
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.truck.MetaTitle + this.translateService.instant('truckpage/metaTitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.truck.MetaDescription + this.translateService.instant('truckpage/metaDescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.truck.MetaAltImage + this.translateService.instant('truckpage/altImage'.toLowerCase())
    });
    this.openTruckPage(event);
  }

  truncateString(string: string): string {
    let truncedString = string.substr(0, 15) + '...';

    return truncedString;
  }

  openTruckPage(event: MouseEvent): void {


    event.preventDefault();

    let navFromHome = this.router.url === '/' ? true : false;
    const make = `${this.truck.specification.make}-${this.truck.specification.model}`;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    this.breadCrumbUrlService.setPageposition(scrollTop, scrollLeft);

    let url = this.localizeRouterService.translateRoute(HtmlRoutePages.truck);
    url = (url as string).replace('//', '/en/');

    this.router.navigate([url, this.truckId], {
      state: { fromHome: navFromHome }
    });
  }

  private initSubscriptions(): void {
    this.s0 = this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.curLang = lang.curLang;
    });

    this.s1 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
      const majorCategorySemiTrailer = 'semi-trailer';
      const majorCategoryTrailer = 'trailer';
      const majorCategoryOther = 'Other';
      const majorCategoryValue = searchOptions.steps.majorCategory.value;
      this.isTrailer = majorCategoryValue === majorCategorySemiTrailer || majorCategoryValue === majorCategoryTrailer;

    });
  }

  private initTruck(): void {
    if (this.isSkeleton) {
      return;
    }

    try {
      const majorCategorySemiTrailer = 'semi-trailer';
      const majorCategoryTrailer = 'trailer';
      const majorCategoryOther = 'Other';
      this.truck = new Truck(this.truck);
      const majorCategoryValue = this.truck.specification.category.toLowerCase();
      this.isTrailer = majorCategoryValue === majorCategorySemiTrailer || majorCategoryValue === majorCategoryTrailer;

      this.truckId = this.truck.stockNumber;
      this.truckMakeModel = this.truck.specification.make + ' ' + this.truck.specification.model;
      
      this.isOtherCategory = this.truck?.specification?.category === majorCategoryOther; //Other category

    } catch (err) {
      console.warn('Error: Truck is missing data');
      console.warn(this.truck);
    }
    // this.truckPicture =
    //   this.truck && this.truck.pictures && this.truck.pictures[0] && this.truck.pictures[0].filenameSmall
    //     ? this.truck.pictures[0].filenameSmall
    //     : '';
    //
    if (this.globalUtils.isBrowser()) {
      try {
        const make = `${this.truck.specification.make}-${this.truck.specification.model}`;
        this.truckUrl = `${document.location.origin}${HtmlRoutePages.truck}/${this.truckId}?make=${encodeURIComponent(
          make
        )}`;
      } catch (err) {
        console.warn('Error: Truck is missing data');
      }
    }
  }

  public handleMissingImage(event: Event,type) {
    this.imageLoaded=false;
  }

  getAltText(): string {
    const make = this.truck?.specification.make || '';
    const model = this.truck?.specification.model || '';
    const horsePower = this.truck?.specification.horsePower || '';
    return `${make} ${model} ${horsePower} truck`.trim();
  }
}
